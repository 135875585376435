import { Box, Button } from '@mui/material';
import { CheckoutButton } from './CheckoutButton';
import { useCallback } from 'react';
import { usePlanDialogContext } from '../usePlanDialogContext';
import { useTranslation } from 'next-i18next';

export const Buttons = () => {
  const {
    canProceed,
    tab,
    nextTab,
    previousTab,
  } = usePlanDialogContext();

  const {
    t,
  } = useTranslation();

  const handleNext = useCallback(async (event: React.MouseEvent) => {
    event.preventDefault();
    await nextTab();
  }, [nextTab,]);

  const handlePrevious = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    previousTab();
  }, [previousTab,]);

  return <Box sx={{
    display: 'flex',
    flexDirection: 'row',
    pt: 5,
    gap: 4,
  }}>
    {tab !== 'seats' ? <Button
      onClick={handlePrevious}
    >
      {t('Previous')}
    </Button> : null}

    <Box sx={{
      flex: '1 1 auto',
    }} />
    {
      tab !== 'summary' ?
        <Button
          disabled={!canProceed}
          variant={'contained'}
          onClick={handleNext}
        >
          {t('Next')}
        </Button> :
        <CheckoutButton />
    }

  </Box>;
};
