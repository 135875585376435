import {
  type PropsWithChildren, createContext, useCallback, useContext, useEffect, useState
} from 'react';
import { SpaceBloc } from 'bloc/space/SpaceBloc';
import { appPlans } from '@shared/schema/src';
import usePathParams from 'utils/hooks/usePathParams';
import type {
  BillingInfo, BillingPeriod, PlanDescriptor, PlanId, Seats
} from '@shared/schema/src';
import type { PlanDialogMode } from '.';
import type { Space, SpaceId, WithId } from '@mindhiveoy/schema';

export type TabName = 'seats' | 'billing' | 'summary';
const tabs = ['seats', 'billing', 'summary',] as TabName[];

export interface PlanDialogContextType {
  billingInfo?: BillingInfo;
  billingPeriod?: BillingPeriod;
  clientSecret?: string;
  currentSeats?: Seats;
  seats?: Seats;
  customerId?: string;
  planId?: PlanId;
  space?: WithId<Space<any, any>>;
  spaceId: SpaceId;
  tab: TabName;
  tabIndex: number;
  canProceed: boolean;
  mode: PlanDialogMode;
  nextCallback?: () => Promise<void>;
  addSeats?: (role: 'users' | 'facilitators', count: number) => void;
  nextTab: () => Promise<void>;
  previousTab: () => void;
  removeSeats?: (role: 'users' | 'facilitators', count: number) => void;
  setBillingInfo: (billingInfo: BillingInfo) => void;
  setBillingPeriod: (billingPeriod: BillingPeriod) => void;
  setTab: (tab: TabName) => void;
  toggleBillingPeriod: () => void;
  updateProceed: (canProceed: boolean) => void;
  setNextCallback: (nextCallback: () => Promise<void>) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => { };
// eslint-disable-next-line @typescript-eslint/no-empty-function
const asyncNoop = async () => { };

const PlanDialogContext = createContext<PlanDialogContextType>({
  planId: undefined,
  spaceId: 'undefined',
  tab: 'seats',
  tabIndex: 0,
  canProceed: true,
  nextCallback: undefined,
  mode: 'upgrade',
  addSeats: noop,
  nextTab: asyncNoop,
  previousTab: noop,
  removeSeats: noop,
  setBillingInfo: noop,
  setBillingPeriod: noop,
  setTab: noop,
  toggleBillingPeriod: noop,
  updateProceed: noop,
  setNextCallback: noop,
});

const getAppPlan = (planId?: PlanId): PlanDescriptor | undefined => {
  if (!planId) {
    return undefined;
  }
  return appPlans[planId];
};

export const PlanDialogContextProvider = ({
  children,
  initial,
}: PropsWithChildren<{
  initial?: PlanDialogContextType;
}>) => {
  const [currentSeats, setCurrentSeats,] = useState<Seats | undefined>(initial?.seats);

  const params = usePathParams();

  const setTab = useCallback((tab: TabName) => {
    setValue((value) => ({
      ...value,
      tab,
      tabIndex: tabs.indexOf(tab),
    }));
  }, []);

  useEffect(() => {
    if (!params.spaceId) {
      return;
    }
    const fetch = async () => {
      const spaceBloc = new SpaceBloc(params);
      const space = await spaceBloc.get();

      if (space) {
        setCurrentSeats(space.seatStats.available);
      }
    };
    fetch();
  }, [params,]);

  const nextTab = useCallback(async () => {
    let callback: any = undefined;
    // We do a little trick here to avoid recreation of then nextTab function.
    // We will call first the set value function just to see if there is a
    // callback function to be called before moving to the next tab.
    setValue((value) => {
      callback = value.nextCallback;
      return value;
    });

    if (callback) {
      await callback();
    }

    setValue((value) => {
      let {
        tabIndex,
      } = value;
      if (tabIndex >= tabs.length - 1) {
        return value;
      }
      tabIndex++;
      return {
        ...value,
        tab: tabs[tabIndex],
        tabIndex,
        canProceed: tabs[tabIndex] in ['summary', 'seats',],
        nextCallback: undefined,
      };
    });
  }, []);

  const previousTab = useCallback(() => {
    setValue((value) => {
      let {
        tabIndex,
      } = value;

      if (tabIndex <= 0) {
        return value;
      }
      tabIndex--;
      return {
        ...value,
        tab: tabs[tabIndex],
        tabIndex,
        nextCallback: undefined,
      };
    });
  }, []);

  const setBillingPeriod = useCallback((billingPeriod: BillingPeriod) => {
    setValue((value) => ({
      ...value,
      billingPeriod,
    }));
  }, []);

  const toggleBillingPeriod = useCallback(() => {
    setValue((value) => ({
      ...value,
      billingPeriod: value.billingPeriod === 'monthly' ? 'annually' : 'monthly',
    }));
  }, []);

  const addSeats = useCallback((role: 'users' | 'facilitators', count = 1) => {
    setValue((value) => {
      const appPlan = getAppPlan(value.planId);
      const {
        seats = appPlan ? {
          facilitators: appPlan.usersIncluded?.minFacilitators ?? 1,
          users: appPlan.usersIncluded?.users ?? 5,
        } : undefined,
      } = value;
      if (!seats) {
        return value;
      }
      const newSeats = {
        ...seats,
        [role]: seats[role] + count,
      };
      return {
        ...value,
        seats: newSeats,
      };
    });
  }, []);

  const removeSeats = useCallback((role: 'users' | 'facilitators', count = 1) => {
    setValue((value) => {
      const appPlan = getAppPlan(value.planId);
      const {
        seats = appPlan ? {
          facilitators: appPlan.usersIncluded?.minFacilitators ?? 1,
          users: appPlan.usersIncluded?.users ?? 5,
        } : undefined,
      } = value;
      if (!seats) {
        return value;
      }
      const newSeats = {
        ...seats,
        [role]: seats[role] - count,
      };
      return {
        ...value,
        seats: newSeats,
      };
    });
  }, []);

  const setBillingInfo = useCallback((billingInfo: BillingInfo) => {
    setValue((value) => ({
      ...value,
      billingInfo,
    }));
  }, []);

  const updateProceed = useCallback((canProceed: boolean) => {
    setValue((value) => ({
      ...value,
      canProceed,
    }));
  }, []);

  const setNextCallback = useCallback((nextCallback: () => Promise<void>) => {
    setValue((value) => ({
      ...value,
      nextCallback,
    }));
  }, []);

  const [value, setValue,] = useState<PlanDialogContextType>(
    () => {
      const result: PlanDialogContextType = {
        ...initial,
        tab: initial?.planId === 'free' ? 'summary' : 'seats',
        spaceId: initial?.spaceId ?? 'undefined',
        tabIndex: initial?.planId === 'free' ? tabs.length - 1 : 0,
        mode: initial?.mode ?? 'upgrade',
        canProceed: initial?.canProceed !== undefined ? initial.canProceed : true,
        billingPeriod: initial?.billingPeriod ?? 'monthly',
        currentSeats,
        addSeats,
        nextTab,
        previousTab,
        removeSeats,
        setBillingInfo,
        setBillingPeriod,
        setTab,
        toggleBillingPeriod,
        updateProceed,
        setNextCallback,
      };
      const appPlan = getAppPlan(initial?.planId);
      if (appPlan) {
        // Validate seats initial values fit the app plan
        const seats = initial?.seats ?? {
          facilitators: 1,
          users: 5,
        };
        seats.facilitators = Math.max(seats.facilitators, appPlan?.usersIncluded?.minFacilitators ?? 1);
        seats.users = Math.max(seats.users, appPlan?.usersIncluded?.users ?? 5);
        result.seats = seats;
      }
      return result;
    });

  return <PlanDialogContext.Provider value={value}>
    {children}
  </PlanDialogContext.Provider>;
};

export const usePlanDialogContext = () => {
  return useContext(PlanDialogContext);
};
