
import { appPlans } from '@shared/schema/src';
import { calculateTotalPrice } from './calculateTotalPrice';
import { capitalizeFirstLetter } from 'utils/common';
import { prettyCurrency } from './SeatsStep';
import { useMemo } from 'react';
import { usePlanDialogContext } from '../usePlanDialogContext';
import { useTranslation } from 'next-i18next';
import Stack from '@mui/material/Stack';
import type { PlanDescriptor } from '@shared/schema/src';

/**
 * This is the last step of the plan dialog. It shows the summary of the order
 * and the total price. When the flow is in this step, The Buttons -component will
 * show the Check out -button to jump into Stripe for the actual payment checkout.
 */
export const SummaryStep = () => {
  const {
    t,
  } = useTranslation();

  const {
    billingPeriod,
    planId,
    mode,
    currentSeats,
    seats,
  } = usePlanDialogContext();

  const {
    totalPrice,
    planPrice,
    changeInPrice,
    extraFacilitatorTotalPrice,
    extraUserUnitPrice,
    extraFacilitatorUnitPrice,
    extraUserTotalPrice,
    extraFacilitators,
    extraUsers,
  } = useMemo(() => {
    const newPlan = calculateTotalPrice({
      billingPeriod,
      seats,
      planId,
    });
    if (currentSeats) {
      const currentPlan = calculateTotalPrice({
        billingPeriod,
        seats: currentSeats,
        planId,
      });
      if (currentPlan.total && newPlan.total) {
        const changeInPrice = newPlan.total - currentPlan.total;

        return {
          ...newPlan,
          changeInPrice: changeInPrice >= 0 ?
            `+${prettyCurrency(changeInPrice)}` : prettyCurrency(changeInPrice),
        };
      }
    }

    return {
      ...newPlan,
      changeInPrice: undefined,
    };
  }, [billingPeriod, currentSeats, planId, seats,]);

  const appPlan = useMemo(() => {
    const appPlan = planId ? appPlans[planId] : undefined;
    return appPlan as PlanDescriptor;
  }, [planId,]);

  const {
    planName,
    planIncluding,
  } = useMemo(() => {
    const usersIncluded = appPlan.usersIncluded?.users ?? 5;
    const facilitatorsIncluded = appPlan.usersIncluded?.minFacilitators ?? 1;

    let planIncluding;
    switch (planId) {
      case 'individual':
        planIncluding = t('Including one facilitator and {{users}} users', {
          facilitator: facilitatorsIncluded,
          users: usersIncluded,
        });
        break;
      case 'team':
      case 'enterprise':
        planIncluding = t('Including {{facilitator}} facilitators and {{users}} users', {
          facilitator: facilitatorsIncluded,
          users: usersIncluded,
        });
    }
    return {
      planName: capitalizeFirstLetter(appPlan.name),
      planIncluding,
    };
  }, [appPlan.name, appPlan.usersIncluded?.minFacilitators, appPlan.usersIncluded?.users, planId, t,]);

  return <Stack gap={3}>
    <table>
      <tr>
        <td><b>{planName} {t('plan')}</b><br /> {planIncluding}</td>
        <td valign='top'
          align="right">{planPrice}</td>
      </tr>
      {extraFacilitators ? <tr>
        <td><b>{extraFacilitators} {t('extra-facilitators')}</b><br /> {extraFacilitatorUnitPrice} {t('per-unit')}</td>
        <td valign='top'
          align="right">{extraFacilitatorTotalPrice}</td>
      </tr> : null}
      {extraUsers ? <tr>
        <td><b>{extraUsers} {t('extra-users')}</b><br /> {extraUserUnitPrice} {t('per-unit')}</td>
        <td valign='top'
          align="right">{extraUserTotalPrice}</td>
      </tr> : null}
      {changeInPrice ? <tr>
        <td>
          <b>{billingPeriod === 'monthly' ? t('Change in total per month') : `${t('Change in total per year')}`}</b>
        </td>
        <td valign='top'
          align="right">{changeInPrice}</td>
      </tr> : null}

      {planId !== 'free' ? <tr>
        <td>
          {

            mode === 'buy-seats' ?
              <b>{
                billingPeriod === 'monthly' ? t('New total per month') : `${t('New total per year')}`}
              </b> :
              <b>{
                billingPeriod === 'monthly' ? t('In total per month') : `${t('In total per year')}`}
              </b>
          }
          <br />
          {t('incl-vat-vat', {
            VAT: 24,
          })}</td>
        <td valign='top'
          align="right"><b>{totalPrice}</b></td>
      </tr> : null}
    </table>
  </Stack>;
};
