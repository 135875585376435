/* eslint-disable @typescript-eslint/no-explicit-any */
import { BlocQuery } from '../../@mindhiveoy/bloc/BlocQuery';
import { createCustomer } from './paymentApi';
import { createUseBlocHook } from '../../@mindhiveoy/react-bloc/createUseBlocDocumentHook';
import type { BlocErrorFunction } from '../../@mindhiveoy/bloc/types';
import type { Customer, CustomerDoc } from '../../@shared/schema/src';
import type { CustomerParams } from '../../@shared/schema/src/pathParams';
import type { UserId, WithId } from '@mindhiveoy/schema';

/**
 *
 */
export class CustomersBloc extends BlocQuery<CustomerDoc, CustomerParams> {
  /**
   * @param {ErrorFunction} onError Error listener
   */
  constructor(
    onError?: BlocErrorFunction
  ) {
    super({
      collectionPath: 'customers',
      params: {} as CustomerParams,
      onError,
    });
  }

  /**
   * Create a new customer.
   * @param {UserId} userId
   * @param {Customer} customer
   * @return {Promise<WithId<Customer>>}
   */
  public create = ({
    userId,
    customer,
  }: {
    userId: UserId,
    customer: Partial<Customer>;
  }) => {
    return this._create(
      async () => createCustomer({
        userId,
        customer,
      }),
      this.onError
    );
  };
}

export const useCustomers =
  createUseBlocHook<WithId<CustomerDoc>, CustomerParams, any>(
    ({
      onError,
    }) => new CustomersBloc(onError)
  );
