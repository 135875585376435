import { Stack } from '@mui/material';
import { usePlanDialogContext } from './usePlanDialogContext';
import CheckIcon from '@mui/icons-material/Check';
import styled from '@emotion/styled';
import theme from 'theme';

const types =
  [
    ['seats',],
    ['-', '-',],
    ['billing',],
    ['-', '-',],
    ['summary',],
  ];

const StepCircle = styled.div(({
  theme,
}) => `
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid ${theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
`);

const Separator = styled.div(({
  theme,
}) => `
  min-width: 25px;
  min-height: 1px;
  max-height: 1px;
  background-color: ${theme.palette.primary.main};
`);

/**
 * Shot the stepper progress bar
 */
export const ProgressBar = () => {
  const {
    tab,
  } = usePlanDialogContext();

  return <Stack
    direction='row'
    justifyContent={'center'}
    alignItems={'center'}
    marginTop={3}
    marginBottom={3}>
    {types.map((type, index) => {
      if (type[0] !== '-') {
        const previousTab = index < types.findIndex((el) => el[0] === tab);
        return <Stack
          key={`${index.toString()}_${type}`}
          justifyContent='center'>
          <StepCircle
            style={{
              borderColor: type[0] === tab || previousTab ? theme.palette.primary.main : theme.palette.text.secondary,
              fontWeight: type[0] === tab ? 'bold' : undefined,
              color: previousTab || type[0] === tab ? theme.palette.primary.main : theme.palette.text.secondary,
            }}>
            {previousTab ?
              <CheckIcon /> :
              index / 2 + 1}</StepCircle>
          <div style={{
            textAlign: 'center',
            fontWeight: type[0] === tab ? 'bold' : undefined,
            opacity: previousTab ? 0.5 : 1,
          }}>{type[1]}</div>
        </Stack>;
      }
      return <Separator key={`${index.toString()}_${type}`} />;
    })}
  </Stack>;
};
