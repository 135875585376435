import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { usePlanDialogContext } from './usePlanDialogContext';
import { useTranslation } from 'next-i18next';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';

export const StepTitleAndIcon = () => {
  const {
    tab,
  } = usePlanDialogContext();

  const {
    t,
  } = useTranslation();

  const {
    text,
  } = useMemo((): {
    text: string;
    icon: JSX.Element;
  } => {
    switch (tab) {
      case 'seats':
        return {
          text: t('choose-the-number-of-seats'),
          icon: <PeopleIcon
            color={'primary'}
            sx={{
              fontSize: 50,
            }} />,
        };
      case 'billing':
        return {
          text: t('add-billing-information'),
          icon: <SettingsIcon
            color={'primary'}
            sx={{
              fontSize: 50,
            }} />,
        };
      case 'summary':
        return {
          text: t('Summary of the order'),
          icon: <CreditCardIcon
            color={'primary'}
            sx={{
              fontSize: 50,
            }} />,
        };
    }
  }, [t, tab,]);

  return <Stack direction='column'
    alignItems='center'
    marginBottom={2}>
    {/* {icon} */}
    <Typography
      margin={0}
      variant='h5'>{text}
    </Typography>
  </Stack>;
};
