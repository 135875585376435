/* eslint-disable @typescript-eslint/no-explicit-any */
// export interface PlanSelectorProps {

import { PlanCard } from './PlanCard';
import { appPlans } from '../../../@shared/schema/src/plans';
import { useAuth } from '@mindhiveoy/react-auth';
import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import DetailAccordion from 'components/billingAndInvoices/components/DetailAccordion';
import styled$ from 'utils/react/styled$';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { BillingPeriod, SpaceId } from '@shared/schema/src';
import type { PlanId } from '../../../@shared/schema/src/plans';

const PlanGrid = styled$.div(({
  theme,
}) => `
  display: grid;
  max-width: 100%;
  width: calc(100% - ${theme.spacing(2 * 3)});
  grid-template-columns: repeat(4, 25%);
  gap: ${theme.spacing(2)};
  
  @media (max-width: ${theme.breakpoints.values.md}px}) {
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 1fr auto 1fr auto;
    grid-gap: ${theme.spacing(2)};
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    grid-template-columns: 1fr;
    grid-gap: ${theme.spacing(2)};
  }
`);

interface PlanSelectorProps {
  alignment?: 'center' | 'flex-start' | 'flex-end'
  currentPlan?: string;
  spaceId?: SpaceId;
  onSwitchPlan?: ((
    plan: PlanId,
    billingPeriod: BillingPeriod,
    mode: 'upgrade' | 'downgrade',
  ) => void) | ((plan: PlanId) => Promise<void>);
}
/**
 * @param {PlanSelectorProps} Props
 * @return {JSX.Element}
 */
const PlanSelector = ({
  currentPlan,
  spaceId,
  onSwitchPlan,
}: PlanSelectorProps
) => {
  const theme = useTheme();

  const [period, setPeriod,] = useState<BillingPeriod>('monthly');
  const [_spaceId, setSpaceId,] = useState<SpaceId | undefined>(spaceId);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const pad = useMediaQuery(theme.breakpoints.down('xl'));
  const isPad = !isMobile && pad;
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

  const {
    user,
  } = useAuth();

  useEffect(() => {
    if (!user) {
      return;
    }
    const {
      ownedSpaces,
    } = user;
    if (!ownedSpaces || Object.keys(ownedSpaces).length === 0) {
      return;
    }
    const spaceId = ownedSpaces[0];
    setSpaceId(spaceId as any);
  }, [user,]);

  const handleSwitchPlan = useCallback(
    (planId: PlanId, mode: 'upgrade' | 'downgrade') => {
      onSwitchPlan?.(planId, period, mode);
    }, [onSwitchPlan, period,]);

  const handleToggleBillingPeriod = useCallback(
    () => {
      setPeriod((period) => period === 'monthly' ? 'annually' : 'monthly');
    }, []);

  return <>
    <PlanGrid>
      <PlanCard
        key={'free'}
        plan={appPlans.free}
        period={period}
        planId={'free' as PlanId}
        spaceId={_spaceId}
        currentPlan={currentPlan as any}
        onToggleBillingPeriod={handleToggleBillingPeriod}
        onSwitchPlan={handleSwitchPlan}
      />
      {isMobile ? <DetailAccordion plans={['free',]} /> : null}
      <PlanCard
        key={'individual'}
        plan={appPlans.individual}
        period={period}
        planId={'individual' as PlanId}
        spaceId={_spaceId}
        currentPlan={currentPlan as any}
        onToggleBillingPeriod={handleToggleBillingPeriod}
        onSwitchPlan={handleSwitchPlan}
      />
      {isMobile ? <DetailAccordion plans={['individual',]} /> : null}
      {isPad ? <DetailAccordion plans={['free', 'individual',]} /> : null}
      <PlanCard
        key={'team'}
        plan={appPlans.team}
        period={period}
        planId={'team' as PlanId}
        spaceId={_spaceId}
        currentPlan={currentPlan as any}
        onToggleBillingPeriod={handleToggleBillingPeriod}
        onSwitchPlan={handleSwitchPlan}
      />
      {isMobile ? <DetailAccordion plans={['team',]} /> : null}
      <PlanCard
        key={'enterprise'}
        plan={appPlans.enterprise}
        period={period}
        planId={'enterprise' as PlanId}
        spaceId={_spaceId}
        currentPlan={currentPlan as any}
        onToggleBillingPeriod={handleToggleBillingPeriod}
        onSwitchPlan={handleSwitchPlan}
      />
      {isMobile ? <DetailAccordion plans={['enterprise',]} /> : null}
      {isPad ? <DetailAccordion plans={['team', 'enterprise',]} /> : null}
      {isDesktop ? <DetailAccordion plans={['free', 'individual', 'team', 'enterprise',]} /> : null}
    </PlanGrid>
  </>;
};

export default PlanSelector;
