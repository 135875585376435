import { USER_BUCKET_SIZE, appPlans } from '@shared/schema/src';
import dinero from 'dinero.js';
import type { BillingPeriod, PlanId, Seats } from '@shared/schema/src';

export const calculateTotalPrice = ({
  billingPeriod,
  planId,
  seats,
}: {
  billingPeriod?: BillingPeriod;
  seats?: Seats;
  planId?: PlanId;
}) => {
  if (!billingPeriod || billingPeriod === 'none') {
    throw new Error('billing period is not set');
  }
  if (!planId) {
    throw new Error('planId is not set');
  }
  if (!seats) {
    throw new Error('seats is not set');
  }
  const d = (amount: number) => dinero({
    amount: amount * 100,
    currency: 'EUR',
  }).setLocale('fi-Fi').toFormat('$0,0.00');

  if (planId === 'free') {
    return {
      planPrice: 'free',
      totalPrice: '-',
      extraUsers: 0,
      extraFacilitators: 0,
      extraFacilitatorTotalPrice: d(0),
      extraFacilitatorUnitPrice: d(0),
      extraUserTotalPrice: d(0),
      extraUserUnitPrice: d(0),
    };
  }
  const appPlan = appPlans[planId];
  if (!appPlan || !appPlan.prices) {
    throw new Error('appPlan is not set');
  }

  // TODO: Calculator class for this
  const extraUsers = Math.max(0, seats.users - (appPlan.usersIncluded?.users ?? 0));
  const extraFacilitators = Math.max(0, seats.facilitators - (appPlan.usersIncluded?.minFacilitators ?? 0));

  const planPrice = appPlan.prices[billingPeriod].base ?? 0;
  let total = planPrice;
  const extraUserUnitPrice = (appPlan.prices[billingPeriod].extraUsers ?? 0) / USER_BUCKET_SIZE;
  const extraUserTotalPrice = extraUsers * extraUserUnitPrice;
  total += extraUserTotalPrice;
  const extraFacilitatorUnitPrice = appPlan.prices[billingPeriod].perFacilitator ?? 0;
  const extraFacilitatorTotalPrice = extraFacilitators * extraFacilitatorUnitPrice;
  total += extraFacilitatorTotalPrice;

  return {
    planPrice: d(planPrice),
    totalPrice: d(total),
    total,
    extraUsers,
    extraFacilitators,
    extraFacilitatorTotalPrice: d(extraFacilitatorTotalPrice),
    extraFacilitatorUnitPrice: d(extraFacilitatorUnitPrice),
    extraUserTotalPrice: d(extraUserTotalPrice),
    extraUserUnitPrice: d(extraUserUnitPrice),
  };
};
