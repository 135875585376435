import { FormControlLabel, Switch } from '@mui/material';
import { Grow } from '../../billingAndInvoices/components/sharedComponents';
import { appPlans } from '../../../@shared/schema/src/plans';
import { motion } from 'framer-motion';
import { useAuth } from '@mindhiveoy/react-auth';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LoadingIndicator from 'components/common/LoadingIndicator';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import RemoveIcon from '@mui/icons-material/Remove';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import styled$ from '../../../utils/react/styled$';
import theme from 'theme';
import usePathParams from 'utils/hooks/usePathParams';
import type { BillingPeriod } from '@shared/schema/src';
import type { PlanDescriptor, PlanId } from '../../../@shared/schema/src/plans';

const CardContainer = styled$.div<{
  $active?: boolean;
  $fullHeight?: boolean;
}>(({
  theme, $active, $fullHeight,
}) => `
  padding: ${theme.spacing(2)};
  border-radius: ${theme.shape.borderRadius}px;
  background-color: ${$active ? theme.palette.background.lightInnerPaper : 'transparent'};
  border: 1px solid ${theme.palette.text.lightgrey};
  ${$active && `
    border: 2px solid ${theme.palette.primary.main};
  `}
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${$fullHeight ? '100%' : 'fit-content'};

  @media(max-width: 599px) {
    margin: auto;
    width: 90%;
  }
  // @media(min-width: 600px) and (max-width:  700px) {
  //   margin: auto;
  //   width: 90%;
  // }
`);

const ActivePlanText = styled(Typography)(({
  theme,
}) => `
  color: ${theme.palette.primary.main};
  text-align: center;
`);

const PlanTitle = styled(Typography)`
  text-align: center;
`;

const Row = styled.div(({
  theme,
}) => `
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-bottom: ${theme.spacing(2)};
`);

const Price = styled.span(({
  theme,
}) => `
  text-align: center;
  font-weight: 500;
  color: ${theme.palette.primary.main};
  font-size: 2.5rem;
  letter-spacing: 0px;
  line-height: 1.5;
  margin-right: ${theme.spacing(1)};
`);

// const Currency = styled.span(({
//   theme,
// }) => `
//   text-align: center;
//   font-weight: 500;
//   color: ${theme.palette.primary.main};
//   font-size: 1.6rem;
//   letter-spacing: 0px;
//   line-height: 1.5;
// `);

const CurrentPlanIcon = styled(CheckIcon)`
  align-self: center;
  font-size: 7.5rem;
`;

const EnterpriseIcon = styled(LocationCityIcon)`
  align-self: center;
  font-size: 7.5rem;
`;

export interface PlanCardProps {
  currentPlan?: PlanId;
  planId: PlanId;
  plan: PlanDescriptor;
  spaceId?: string;
  period: BillingPeriod;
  onSwitchPlan?: (
    plan: PlanId,
    mode: 'upgrade' | 'downgrade'
  ) => void;
  onToggleBillingPeriod?: (period: BillingPeriod) => void;
}

const planList = Object.keys(appPlans);
/**
 * @param {PlanCardProps} Props
 * @return {JSX.Element}
 */
export const PlanCard = ({
  currentPlan,
  period,
  plan,
  planId,
  spaceId,
  onToggleBillingPeriod,
  onSwitchPlan,
}: PlanCardProps) => {
  const {
    user,
  } = useAuth();

  const {
    t,
  } = useTranslation();

  const pathParams = usePathParams();

  const [creating,] = useState(false);
  const mode = useRef<'upgrade' | 'downgrade'>('upgrade');

  const handleBuyClick = useCallback(async (event: React.MouseEvent) => {
    onSwitchPlan?.(planId, mode.current);
    event.stopPropagation();
  }, [mode, onSwitchPlan, planId,]);

  // TODO: Make generic and based on configuration
  const isFreePlan: boolean = planId === 'free';
  const isEnterprisePlan: boolean = planId === 'enterprise';
  const isCurrentPlan: boolean = planId === currentPlan;

  const renderPricing = useCallback(
    (plan: PlanDescriptor) => {
      switch (plan.pricing) {
        case 'free':
          return t('forever');
        case 'per-seat': {
          if (period === 'none') {
            return null;
          }
          const pricing = plan.prices?.[period];
          const divider = period === 'monthly' ? 1 : 12;

          return <>
            {((pricing?.base ?? 0) / divider).toFixed(0)}&nbsp;€&nbsp;/&nbsp;{t('month')}
          </>;
        }
        default:
          return t('ask-for-pricing');
      }
    }, [t, period,]);

  const renderUsers = useCallback(
    (plan: PlanDescriptor) => {
      if (period === 'none') {
        return null;
      }

      const users = plan.usersIncluded;
      const pricing = plan.prices?.[period];
      const extension = planId === 'enterprise' ? '+' : '';
      const divider = period === 'monthly' ? 1 : 12;
      return <div>
        {users ?
          <table>
            <tbody>
              <tr>
                <td align="right">{users.minFacilitators}{extension}</td>
                <td>{users.minFacilitators === 1 ? t('facilitator') : t('facilitators-2')}</td>
              </tr>
              <tr>
                <td align="right">
                  {users.users}{extension}</td>
                <td>
                  {t('users-2')}
                </td>
              </tr>
              {plan?.usersIncluded?.minFacilitators !== plan?.usersIncluded?.maxFacilitators && planId !== 'enterprise' ?
                <tr >
                  <td style={{
                    paddingTop: theme.spacing(2),
                  }}>+{((pricing?.perFacilitator ?? 0) / divider).toFixed(0)}&nbsp;€</td>
                  <td style={{
                    paddingTop: theme.spacing(2),
                    whiteSpace: 'nowrap',
                  }}>/&nbsp;{t('extra fasilitator')}</td>
                </tr> : planId === 'individual' ? <tr>
                  <td style={{
                    paddingTop: theme.spacing(2),
                  }}>
                    &nbsp;
                  </td>
                </tr> : null
              }
            </tbody>
          </table> : null
        }
      </div>;
    }, [period, planId, t,]);

  const resolveButton = useCallback(() => {
    const planIndex = planList.findIndex((el) => el === planId);
    const currentPlanIndex = planList.findIndex((el) => el === currentPlan);

    if (planId === 'enterprise') {
      return t('Contact us');
    }

    if (!currentPlan) {
      if (planId === 'free') {
        return t('get-started');
      }
      return t('order');
    }

    if (planIndex > currentPlanIndex) {
      mode.current = 'upgrade';
      return t('Upgrade');
    }
    mode.current = 'downgrade';
    return t('Downgrade');
  }, [currentPlan, planId, t,]);

  const resolveIcon = useCallback(() => {
    const planIndex = planList.findIndex((el) => el === planId);
    const currentPlanIndex = planList.findIndex((el) => el === currentPlan);

    if (planId === 'enterprise') {
      return <ContactMailIcon />;
    }

    if (!currentPlan) {
      if (planId === 'free') {
        return <AddIcon />;
      }
      return <ShoppingCartIcon />;
    }

    if (planIndex > currentPlanIndex) {
      return <AddIcon />;
    }
    return <RemoveIcon />;
  }, [currentPlan, planId,]);

  const handleTogglePeriod = (event: React.SyntheticEvent, checked: boolean) => {
    onToggleBillingPeriod?.(checked ? 'monthly' : 'annually');
  };

  const isButtonVisible = useCallback(() => {
    if (!user || user.isAnonymous || currentPlan === undefined) {
      return planId === 'free';
    }
    const {
      ownedSpaces,
    } = user;

    const space = spaceId ?? pathParams.spaceId;
    const ownsSpace = space ? !!ownedSpaces?.includes(space) : false;

    return ownsSpace && !isCurrentPlan;
  }, [currentPlan, isCurrentPlan, pathParams.spaceId, planId, spaceId, user,]);

  return <CardContainer $fullHeight
    $active={isCurrentPlan}>

    {isCurrentPlan &&
        <ActivePlanText variant='h6'
          fontWeight={600}
          marginTop={0}
          marginBottom={2}
          height={50}>
          {t('Your plan')}
        </ActivePlanText>}
    <PlanTitle
      variant="h2"
      marginTop={isCurrentPlan ? 0 : 4}
    >
      {t(plan.name)}
    </PlanTitle>

    <Row>
      <div>
        <Price
          style={{
            marginRight: 0,
            fontSize: '1.4rem',
            color: theme.palette.primary.main,
          }}>
          {renderPricing(plan)}
        </Price>
      </div>
    </Row>
    <Row>
      {renderUsers(plan)}
    </Row>

    {plan.pricing === 'per-seat' ? <motion.div
      layout
    >
      <FormControlLabel
        value={period === 'monthly'}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
        }}
        control={<Switch
          checked={period === 'monthly'}
          onChange={handleTogglePeriod}
        />}
        label={period === 'monthly' ? t('Monthly') : t('Annually')}
      />
    </motion.div> : null}

    {/* TEMPORARY - FOR BETA MODE */}
    {<Grow $alignment='center'>
      {
        // TODO: need to be based on schema theme / schema
        isEnterprisePlan && <EnterpriseIcon />
      }
    </Grow>}

    {isCurrentPlan && <CurrentPlanIcon />}

    {!isCurrentPlan && <>
      {creating ? <LoadingIndicator /> :
        isButtonVisible() && <Button
          variant="contained"
          sx={{
            marginTop: theme.spacing(3),
            whiteSpace: 'nowrap',
          }}
          color={isFreePlan ? spaceId ? 'inherit' : 'secondary' : 'primary'}
          onClick={handleBuyClick}
          startIcon={!spaceId ? resolveIcon() : undefined}
        >
          {resolveButton()}
        </Button>
      }
    </>}
  </CardContainer>;
};
