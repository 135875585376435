
import { createCustomer, updateCustomer } from 'bloc/payments/paymentApi';
import { useAuth } from '@mindhiveoy/react-auth';
import { usePlanDialogContext } from '../usePlanDialogContext';
import BillingSettings from '../../component/BillingSettings';
import React, { useCallback, useEffect } from 'react';
import type { BillingInfo } from '@shared/schema/src';

export const BillingInfoStep = () => {
  const {
    billingInfo,
    setBillingInfo,
    updateProceed,
    setNextCallback,
  } = usePlanDialogContext();

  const {
    user,
  } = useAuth();

  const handleSaveBillingSettings = useCallback(
    (billingInfo: BillingInfo, isValid: boolean) => {
      setBillingInfo(billingInfo);
      updateProceed(isValid);
    }, [setBillingInfo, updateProceed,]);

  useEffect(() => {
    setNextCallback(async () => {
      if (!user?.uid) {
        return;
      }
      const customerId = user?.customerId;
      // TODO: Make this as create or update at the backend
      if (!customerId) {
        await createCustomer({
          userId: user.uid,
          customer: {
            name: billingInfo?.name,
            billingInfo,
          },
        });
        return;
      }
      await updateCustomer({
        customerId,
        customer: {
          name: billingInfo?.name,
          billingInfo,
        },
        deleted: [],
      });
    });
  }, [billingInfo, setNextCallback, user?.customerId, user?.uid,]);

  return <BillingSettings
    mode="embedded"
    initialBillingInfo={billingInfo}
    onDataChange={handleSaveBillingSettings}
  />;
};
