import { BillingInfoStep } from './steps/BillingInfoStep';
import { SeatsStep } from './steps/SeatsStep';
import { SummaryStep } from './steps/SummaryStep';
import { usePlanDialogContext } from './usePlanDialogContext';

export const Stepper = () => {
  const {
    tab,
  } = usePlanDialogContext();

  switch (tab) {
    case 'seats':
      return <SeatsStep />;
    case 'billing':
      return <BillingInfoStep />;
    case 'summary':
      return <SummaryStep />;
  }
  return null;
};
