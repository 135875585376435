/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/no-duplicate-string */
import { DialogBase } from '../../sharedComponents';

import { Button, Divider, Stack, Typography } from '@mui/material';
import { Buttons } from './Buttons';
import { PlanDialogContextProvider, usePlanDialogContext } from './usePlanDialogContext';
import { ProgressBar } from './ProgressBar';
import { StepTitleAndIcon } from './StepTitleAndIcon';
import { Stepper } from './Stepper';
import { capitalizeFirstLetter } from 'utils/common';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import type { BillingInfo } from '@shared/schema/src/payments/BillingInfo';
import type { BillingPeriod, PlanId, Seats, Space } from '@shared/schema/src';
import type { SpaceId, WithId } from '@mindhiveoy/schema';

const Emphasize = styled.span(({
  theme,
}) => `
  color: ${theme.palette.primary.main};
`);

const StepContainer = styled.div`
  overflow-y: auto;
  max-height: 60vh;
  flex-grow: 1;
`;

const CloseButton = styled(Button)(({
  theme,
}) => `
  position: absolute;
  top: ${theme.spacing(1)};
  right: -8px;
  color: inherit;
`);

const NewCardBase = styled.div(({
  theme,
}) => `
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  background-color: ${theme.palette.background.default};
  justify-content: space-between;
  padding: ${theme.spacing(6, 4)};
  height: auto;
  overflow: hidden;
  @media(max-width: 600px) {
    padding: ${theme.spacing(3, 2)};
  }
`);

const SpaceName = styled(Typography)(({
  theme,
}) => `
  margin: 0;
  font-size: 18px;
  color: ${theme.palette.primary.main};
`);

export const OrderSummaryTitle = styled.div(({
  theme,
}) => `
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 2rem;
  color: ${theme.palette.text.primary};
`);

export const StyledDivider = styled(Divider)(({
  theme,
}) => `
  border-color: ${theme.palette.text.secondary};
  margin: 0;
`);

export const Details = styled(Typography)`
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2;
  margin: 0;
`;

export type CloseOnPlanChangeDialogArgs = ConfirmedCloseEventArgs | CanceledCloseEventArgs;

interface ConfirmedCloseEventArgs {
  confirmed: true;
  billingInfo: BillingInfo;
  seats: Seats;
  planId: PlanId;
}

interface CanceledCloseEventArgs {
  confirmed: false;
}

export type PlanDialogMode = 'upgrade' | 'buy-seats' | 'downgrade';

interface UpgradeSubscriptionDialogProps {
  mode: PlanDialogMode;
  open?: boolean;
  planId?: PlanId;
  space?: WithId<Space>;
  spaceId?: SpaceId;
  seats?: Seats;
  billingPeriod?: BillingPeriod;
  onClose?: (args: CloseOnPlanChangeDialogArgs) => void;
}

export type TabName = 'seats' | 'billing' | 'payment' | 'summary';

export type CardStatus = 'empty' | 'incomplete' | 'ready';

const ChangePlanDialog = (props: UpgradeSubscriptionDialogProps) => {
  return <PlanDialogContextProvider initial={props as any}>
    <Dialog {...props} />
  </PlanDialogContextProvider>;
};

const Dialog = ({
  open = false,
  planId,
  onClose,
}: UpgradeSubscriptionDialogProps) => {
  const {
    space,
    mode,
  } = usePlanDialogContext();

  const {
    t,
  } = useTranslation();

  // Function called when user press cancel at the top the the dialog. Saving billing info.
  const handleClose = useCallback(() => {
    onClose?.({
      confirmed: false,
    });
  }, [onClose,]);

  const title = useMemo(() => {
    switch (mode) {
      case 'upgrade':
        return <>{t('upgrade-to')} <Emphasize>{capitalizeFirstLetter(planId)}</Emphasize></>;
      case 'buy-seats':
        return 'Change the number of seats';
      case 'downgrade':
        return <>
          Downgrade plan to <Emphasize>{capitalizeFirstLetter(planId)}</Emphasize>
        </>;
    }
  }, [mode, planId, t,]);

  return <DialogBase
    open={open}
    fullWidth={true}
    maxWidth='sm'
  >
    <NewCardBase>
      <CloseButton
        onClick={handleClose}>
        <CloseIcon />
      </CloseButton>

      <Stack
        direction='column'
        alignItems='center'
        gap={1}>

        <Typography margin={0}
          variant='h2'>
          {title}
        </Typography>
        <SpaceName> for <b>{space?.name}</b></SpaceName>
      </Stack>

      <ProgressBar />
      <StepTitleAndIcon />

      <StepContainer>
        <Stepper />
      </StepContainer>
      <Buttons />

    </NewCardBase>
  </DialogBase>;
};

export default ChangePlanDialog;
