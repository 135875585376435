import { Stack, Tooltip, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import AnimateHeight from 'components/common/AnimateHeight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';
import theme from 'theme';
import type { PlanId } from '@shared/schema/src';

const ArrowIcon = styled(KeyboardArrowDownIcon)(({
  theme,
}) => `
  color: ${theme.palette.primary.main};
`);

const PlanTitle = styled(Typography)`
  ${theme.breakpoints.down('sm')} {
    display: inline;
    color: ${theme.palette.primary.main};
    font-weight: 500;
    font-size: 1.2rem;
    text-transform: capitalize;
  }
  display: none;
`;

const FeaturesStack = styled(Stack)`
  ${theme.breakpoints.down('sm')} {
    align-items: center;
    justify-content: center;
  }
  align-items: flex-start;
  justify-content: flex-start;
`;

const DetailsStack = styled$(Stack)<{
  $height?: string;
}>(({
  $height,
}) => `
  ${theme.breakpoints.down('sm')} {
  height: auto;
  }
  margin-bottom: ${theme.spacing(2)};
  height: ${$height ? $height : 'auto'};
  `);

const DetailTitle = styled.div(({
  theme,
}) => `
 flex-shrink: 0;
  font-weight: 600;
  color: ${theme.palette.primary.main};
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.5;
  margin-bottom: ${theme.spacing(1)};
`);

const Details = styled(Typography)`
  ${theme.breakpoints.down('sm')} {
    text-align: center;
  }
  margin-top: 0;
  margin-bottom: ${theme.spacing(0.5)};
  font-weight: 200;
  font-style: normal;
  font-size: 0.8rem;
  line-height: 1.5;
  cursor: default;
  :hover {
    text-decoration: underline;
    text-decoration-color: ${theme.palette.primary.main};
    text-underline-offset: 0.25rem;
  }
`;

// const plans = Object.keys(appPlans);

const Arrow = styled$(ArrowIcon)<{
  $expanded: boolean;
}>(({
  theme,
  $expanded,
}) => `
  color: ${theme.palette.primary.main};
  transform: rotate(${$expanded ? '180deg' : '0deg'});
  transition: transform 0.2s ease-in-out;
`);

interface FeatureContainerProps {
  title: string;
  data: any;
  plan: string;
  height?: string;
}

interface FeaturesProps {
  planId: PlanId;
}

export const FeatureContainer = ({
  title,
  data,
  plan,
  height,
}: FeatureContainerProps) => {
  const planTiers = {
    free: 1,
    individual: 2,
    team: 3,
    enterprise: 4,
  };

  const features = data.filter((feature: any) => planTiers[plan as keyof typeof planTiers] >= feature.lowestTierAvailable);

  return <>
    <DetailTitle>{title}</DetailTitle>
    <DetailsStack $height={height}>
      {features.map((feature: any, index: number) => {
        return <Tooltip
          key={index}
          title={feature.description}
          arrow
          placement='top-start'
          componentsProps={{
            tooltip: {
              sx: {
                'bgcolor': theme.palette.background.innerPaper,
                'padding': theme.spacing(2),
                'fontSize': '0.75rem',
                'fontWeight': 400,
                'lineHeight': '1.25rem',
                '& .MuiTooltip-arrow': {
                  color: theme.palette.background.innerPaper,
                },
              },
            },
          }}>
          <Details>{feature.title}</Details>
        </Tooltip>;
      })}
    </DetailsStack>
  </>;
};

export const Features = ({
  planId,
}: FeaturesProps) => {
  const {
    t,
  } = useTranslation();

  const data = useMemo(() => ({
    usage: [{
      title: t('Unlimited projects and sessions'), description: t('unlimited-project-and-sessions-description'), lowestTierAvailable: 1,
    }, {
      title: t('Unlimited 2D, Slider and Open questions'), description: t('unlimited-2d-slider-and-open-questions-description'), lowestTierAvailable: 1,
    },
    {
      title: t('Expert Matrix'), description: t('expert-matrix-description'), lowestTierAvailable: 2,
    },
    {
      title: t('Self-Paced Mode'), description: t('self-paced-mode-description'), lowestTierAvailable: 1,
    },
    {
      title: t('Live Mode'), description: t('live-mode-description'), lowestTierAvailable: 2,
    },
    {
      title: t('Open Mode'), description: t('open-mode-description'), lowestTierAvailable: 4,
    },
    ],
    views: [
      {
        title: t('Facilitation tools'), description: t('facilitation-tools-description'), lowestTierAvailable: 1,
      },
      {
        title: t('Panelist tools'), description: t('panelist-tools-description'), lowestTierAvailable: 1,
      },
    ],
    collaboration: [
      {
        title: t('Messaging'), description: t('messaging-description'), lowestTierAvailable: 1,
      },
      {
        title: t('Notifications'), description: t('notifications-description'), lowestTierAvailable: 1,
      },
      {
        title: t('Mentions'), description: t('mentions-description'), lowestTierAvailable: 1,
      },
      {
        title: t('Facilitation Labels'), description: t('facilitation-labels-description'), lowestTierAvailable: 2,
      },
    ],
    reporting: [
      {
        title: t('statistics'), description: t('statistics-description'), lowestTierAvailable: 1,
      },
      {
        title: t('Export data'), description: t('export-data-description'), lowestTierAvailable: 2,
      },
    ],
    support: [
      {
        title: t('Chat support'), description: t('chat-support-description'), lowestTierAvailable: 2,
      },
      {
        title: t('Onboarding webinar'), description: t('onboarding-webinar-description'), lowestTierAvailable: 2,
      },
      {
        title: t('Facilitator training workshop'), description: t('facilitator-training-workshop-description'), lowestTierAvailable: 3,
      },
      {
        title: t('Live-mode training workshop'), description: t('live-mode-training-workshop-description'), lowestTierAvailable: 4,
      },
    ],
  }), [t,]);

  return <FeaturesStack paddingLeft={2}>
    <PlanTitle>
      {planId.toString()}
    </PlanTitle>
    <FeatureContainer title={t('Usage')}
      data={data.usage}
      plan={planId}
      height='11rem' />
    <FeatureContainer title={t('Views and Tools')}
      data={data.views}
      plan={planId}
      height='4rem' />
    <FeatureContainer title={t('Collaboration')}
      data={data.collaboration}
      plan={planId}
      height='7rem' />
    <FeatureContainer title={t('Reporting')}
      data={data.reporting}
      plan={planId}
      height='4rem' />
    <FeatureContainer title={t('Support')}
      data={data.support}
      plan={planId} />
  </FeaturesStack>;
};

const ArrowRow = styled$.div<{
  $span: number;
}>(({
  $span,
}) => `
  grid-column: 1 / span ${$span};
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`);

interface DetailAccordionProps {
  plans: PlanId[];
}

const DetailAccordion = ({
  plans,
}: DetailAccordionProps) => {
  const [expanded, setExpanded,] = useState(false);

  const handleToggle = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, []);

  const {
    t,
  } = useTranslation();

  return <>
    <ArrowRow $span={plans.length}
      onClick={handleToggle}>
      <Arrow $expanded={expanded} />{expanded ? t('Hide details') : t('Show details')}
    </ArrowRow>
    {
      plans.map((planId) =>
        <div key={planId}>
          <AnimateHeight visible={expanded}>
            <Features planId={planId} />
          </AnimateHeight>
        </div>)
    }
  </>;
};

export default DetailAccordion;
