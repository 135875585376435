/* eslint-disable sonarjs/no-duplicate-string */

import { CustomersBloc } from 'bloc/payments/CustomersBloc';
import { useAuth } from '@mindhiveoy/react-auth';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCustomer } from 'bloc/payments/CustomerBloc';
import { useTranslation } from 'next-i18next';
import { validateEmail } from 'utils/formValidators/validateEmail';
import SimpleComponentForm from 'components/forms/SimpleComponentForm';
import type { BillingInfo } from '@shared/schema/src/payments/BillingInfo';
import type { BuilderConfig } from 'components/builder/builders/componentBuilder';
import type { Customer } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

interface Form extends BillingInfo {
  isBusiness: boolean;
}

interface BillingSettingsProps {
  /**
   * If mode is 'independent', the billing info is saved for the customer and the
   * customer is created if it does not exist.
   * If mode is 'embedded', the billing info save is delegated to the parent component
   * with the onSave event handler.
   */
  mode?: 'independent' | 'embedded';

  initialBillingInfo?: BillingInfo;
  /**
   * Event handler for saving the billing info. This is only used when mode is 'embedded'.
   * @param {BillingInfo} billingInfo   The billing info to save.
   */
  onDataChange?: (billingInfo: BillingInfo, isValid: boolean, deleted: string[]) => void;
}

const BillingSettings = ({
  mode = 'independent',
  initialBillingInfo,
  onDataChange,
}: BillingSettingsProps) => {
  const {
    t,
  } = useTranslation();

  const {
    user,
  } = useAuth();

  const customerBloc = useCustomer({
    customerId: user?.customerId,
  });

  const [customer, setCustomer,] = useState<WithId<Customer>>();

  useEffect(() => customerBloc.subscribe((customerDoc) => {
    if (!customerDoc?.content) {
      setCustomer(undefined);
      return;
    }
    setCustomer({
      ...customerDoc.content,
      _id: customerDoc._id,
    }
    );
    setBillingInfo(customerDoc.content.billingInfo);
  }), [customerBloc,]);

  const [billingInfo, setBillingInfo,] = useState<BillingInfo | undefined>(initialBillingInfo);

  const config: BuilderConfig<Partial<Form>> = useMemo(() => ({
    type: 'billingInfo',
    props: {
      name: {
        type: 'text',
        displayName: t('Name of the organization or person'),
        required: true,
      },
      vatId: {
        type: 'text',
        displayName: t('Vat Id'),
        xs: 4,
      },
      extraInformation: {
        type: 'text',
        xs: 8,
        displayName: t('Your reference'),
      },
      streetAddress: {
        type: 'text',
        displayName: t('Street address'),
        section: t('Invoicing address'),
      },
      postalCode: {
        type: 'text',
        xs: 4,
        displayName: t('Postal code'),
      },
      city: {
        type: 'text',
        xs: 8,
        displayName: t('City'),
      },
      country: {
        type: 'country-picker',
        xs: 4,
        displayName: t('Country'),
      },
      email: {
        type: 'text',
        xs: 8,
        displayName: t('Email address'),
        defaultValue: user?.email,
        validate: validateEmail(t),
      },
      phoneNumber: {
        type: 'phone-number',
        displayName: t('Phone number'),
      },
    },
  }), [t, user?.email,]);

  const handleSave = useCallback(async (_billingInfo: Partial<Form>, deleted: string[]) => {
    if (!user?.uid) {
      return;
    }
    const billingInfo = _billingInfo as BillingInfo;
    setBillingInfo(billingInfo);

    switch (mode) {
      case 'independent': {
        const customersBloc = new CustomersBloc();

        if (!customer) {
          const response = await customersBloc.create({
            userId: user?.uid,
            customer: {
              name: billingInfo.name,
              billingInfo,
            },
          });
          setCustomer({
            ...response.content,
            _id: response._id,
          });
          setBillingInfo(response.content.billingInfo);
          return;
        }
        const response = await customerBloc.update(
          {
            billingInfo,
          },
          deleted.map((key) => `content.billingInfo.${key}`)
        );
        setCustomer({
          ...response.content,
          _id: response._id,
        });
        setBillingInfo(response.content.billingInfo);
        break;
      }
      case 'embedded': {
        break;
      }

      default:
        throw new Error(`Unknown mode ${mode}`);
    }
  }, [customer, customerBloc, mode, user?.uid,]);

  const handleDataChange = useCallback((form: Partial<Form>, isValid: boolean, deleted: string[]) => {
    onDataChange?.(form as BillingInfo, isValid, deleted);
  }, [onDataChange,]);

  return <SimpleComponentForm<Partial<Form>>
    config={config}
    initialData={billingInfo as Form}
    onSave={mode === 'independent' ? handleSave : undefined}
    onDataChange={mode === 'embedded' ? handleDataChange : undefined}
  />;
};

export default BillingSettings;
