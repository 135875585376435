import type { Customer } from '@shared/schema/src';

import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { createCheckoutSession } from 'bloc/payments/paymentApi';
import { loadStripe } from '@stripe/stripe-js';
import { motion } from 'framer-motion';
import { useAuth } from '@mindhiveoy/react-auth';
import { useCustomer } from 'bloc/payments/CustomerBloc';
import { useEffect, useState } from 'react';
import { usePlanDialogContext } from '../usePlanDialogContext';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import LoadingIndicator from 'components/common/LoadingIndicator';
import config from 'config';
import styled from '@emotion/styled';
import type { WithId } from '@mindhiveoy/schema';

const stripePromise = loadStripe(config.payment.stripe.key);

export const SubscribeButton = styled(motion(Button))(({
  theme,
}) => `
  color: ${theme.palette.getContrastText(theme.palette.secondary.main)};
  background-color: ${theme.palette.secondary.main};
`);

// Create a Checkout Form component
export const CheckoutButton = () => {
  return <Elements stripe={stripePromise}>
    <CheckoutButtonImpl />
  </Elements>;
};

const CheckoutButtonImpl = () => {
  const stripe = useStripe();
  const elements = useElements();

  const {
    billingPeriod,
    planId,
    seats,
    spaceId,
  } = usePlanDialogContext();

  const {
    user,
  } = useAuth();

  const {
    t,
  } = useTranslation();

  const [checkingOut, setCheckingOut,] = useState(false);
  const [customer, setCustomer,] = useState<WithId<Customer>>();

  const customerBloc = useCustomer({
    customerId: user?.customerId,
  });

  useEffect(() => customerBloc.subscribe(
    (customerDoc) => {
      setCustomer(customerDoc ? {
        ...customerDoc?.content,
        _id: customerDoc._id,
      } : undefined);
    }), [customerBloc,]
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements || !customer?.stripeCustomerId) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.error('Stripe.js has not yet loaded.');
      return;
    }

    if (!planId) {
      console.error('No planId');
      return;
    }
    if (!billingPeriod) {
      console.error('No billingPeriod');
      return;
    }
    setCheckingOut(true);
    try {
      // Create a Checkout Session on the server
      const response = await createCheckoutSession({
        mode: 'subscription',
        customerId: customer?.stripeCustomerId,
        plan: planId,
        billingPeriod,
        facilitators: seats?.facilitators,
        users: seats?.users,
        spaceId,
      });
      const sessionId = response.sessionId;

      // Redirect to Checkout
      const result = await stripe.redirectToCheckout({
        sessionId,
      });

      if (result.error) {
        // Show an error to the user
        console.error(result.error.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    checkingOut ? <LoadingIndicator tiny /> : <SubscribeButton
      variant="contained"
      disabled={!stripe || checkingOut}
      whileHover={{
        scale: 1.05,
        backgroundColor: '#88aa40',
      }}
      onClick={handleSubmit}
    >
      {planId !== 'free' ? t('check-out') : t('Downgrade')}
    </SubscribeButton>
  );
};
