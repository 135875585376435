import { OrderSummaryTitle } from '..';
import { SeatType, appPlans } from '@shared/schema/src';
import { Stack, Switch } from '@mui/material';
import { calculateTotalPrice } from './calculateTotalPrice';
import { useCallback, useMemo } from 'react';
import { usePlanDialogContext } from '../usePlanDialogContext';
import { useTranslation } from 'next-i18next';
import AddOrSubtract from 'components/billingAndInvoices/BuySeats/components/AddOrSubtract';
import dinero from 'dinero.js';
import type { PlanDescriptor } from '@shared/schema/src';

export const prettyCurrency = (amount: number) => dinero({
  amount: amount * 100,
  currency: 'EUR',
}).setLocale('fi-Fi').toFormat('$0,0.00');

export const SeatsStep = () => {
  const {
    planId,
    billingPeriod,
    mode,
    seats = {
      facilitators: 1,
      users: 20,
    },
    currentSeats,
    toggleBillingPeriod,
    addSeats,
    removeSeats,
  } = usePlanDialogContext();

  const {
    t,
  } = useTranslation();

  const appPlan = useMemo(() => {
    const appPlan = planId ? appPlans[planId] : undefined;
    return appPlan as PlanDescriptor;
  }, [planId,]);

  // Price component renderer
  const {
    totalPrice,
    changeInPrice,
  } = useMemo(() => {
    const total = calculateTotalPrice({
      billingPeriod,
      seats,
      planId,
    });
    if (!total.total) {
      return {
        totalPrice: total.totalPrice,
      };
    }
    if (mode === 'buy-seats') {
      const currentPrice = calculateTotalPrice({
        billingPeriod,
        seats: currentSeats,
        planId,
      });
      if (!currentPrice.total) {
        return {
          totalPrice: total.totalPrice,
        };
      }

      const changeInPrice = total.total - currentPrice.total;
      return {
        totalPrice: total.totalPrice,
        changeInPrice: changeInPrice >= 0 ? `+${prettyCurrency(changeInPrice)}` : prettyCurrency(changeInPrice),
      };
    }
    return {
      totalPrice: total.totalPrice,
    };
  }, [billingPeriod, currentSeats, mode, planId, seats,]);

  const handleTogglePeriod = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    toggleBillingPeriod();
  }, [toggleBillingPeriod,]);

  const minFacilitators = appPlan.usersIncluded?.minFacilitators ?? 1;
  const maxFacilitators = appPlan.usersIncluded?.maxFacilitators ?? 1;
  const minUsers = appPlan.usersIncluded?.users ?? 5;

  const handleAddUser = useCallback((
    role: 'users' | 'facilitators'
  ) => {
    const seats = role === 'users' ? 10 : 1;
    addSeats?.(role, seats);
  }, [addSeats,]);

  const handleRemoveUser = useCallback((
    role: 'users' | 'facilitators'
  ) => {
    const seats = role === 'users' ? 10 : 1;
    removeSeats?.(role, seats);
  }, [removeSeats,]);

  return <>
    <Stack direction='row'
      alignSelf='center'
      justifyContent="center"
      padding={4}
      gap={6}>
      <AddOrSubtract
        price={billingPeriod && billingPeriod !== 'none' ? (appPlan?.prices?.[billingPeriod]?.perFacilitator ?? 0) * 100 : 0}
        min={minFacilitators}
        max={maxFacilitators}
        count={Math.max(seats.facilitators, minFacilitators)}
        type={SeatType.FACILITATOR}
        role="facilitators"
        billingPeriod={billingPeriod == 'monthly' ? 'monthly' : 'annually'}
        onAdd={handleAddUser}
        onRemove={handleRemoveUser}
      />
      <AddOrSubtract
        price={billingPeriod && billingPeriod !== 'none' ? (appPlan?.prices?.[billingPeriod]?.extraUsers ?? 0) * 100 : 0}
        min={minUsers}
        max={appPlan.usersIncluded?.maxUsers ?? 10}
        count={Math.max(seats.users, minUsers)}
        type={SeatType.USER}
        role="users"
        billingPeriod={billingPeriod == 'monthly' ? 'monthly' : 'annually'}
        onAdd={handleAddUser}
        onRemove={handleRemoveUser}
      />
    </Stack>

    <Stack gap={1}
      marginTop={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >

      <div>
        {t('Monthly')}
        <Switch
          checked={billingPeriod !== 'monthly'}
          onClick={handleTogglePeriod}
        />
        {t('Annually')} ({
          t('{{percent}}% off', {
            percent: 16,
          })}
        )
      </div>
      {
        mode === 'buy-seats' ?
          <Stack direction='row'
            justifyContent="center"
            gap={2}
          >
            <OrderSummaryTitle>{t('Change in price')}</OrderSummaryTitle>
            <OrderSummaryTitle> {changeInPrice} / {billingPeriod === 'monthly' ? t('Month') : `${t('Year')}`}</OrderSummaryTitle>

          </Stack> :
          null
      }

      <Stack direction='row'
        justifyContent="center"
        gap={2}
      >
        <OrderSummaryTitle>{
          mode === 'buy-seats' ? t('New total') : t('Total')}</OrderSummaryTitle>
        <OrderSummaryTitle>{totalPrice} / {billingPeriod === 'monthly' ? t('Month') : `${t('Year')}`}</OrderSummaryTitle>
      </Stack>
    </Stack>
  </>;
};
